import { Answer } from './answer';

export class UserAssessmentQuestion {
    QuestionId: number;
    QuestionText: string;
    DomainId: number;
    ComplexityId: number;
    QuestionTypeId: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    userAnswer: string;
    MultiAnswer: boolean;
    QuestionStateChanged: boolean;
    CorrectAnswer: string;
    CorrectAnswerMulti: Array<number>;
    answers: Array<Answer>;
}