export class UserAssessmentPanelGridModel {
    UserAssessmentId: string;    
    AssessmentName: string;
    CandidateName: string;
    IsReviewed : boolean;
}
export class UserAssessmentResponse {
    Question?: string;
    UserAnswer? : string;
    UserResponseId: number;
    Rating: number;
    Remarks: string;
    IsAnswerCorrect: boolean;
}
export class UserAssessmentReviewModel {
    UserAssessmentId : string;
    UserResponses : Array<UserAssessmentResponse>;
    UserId: number;
}