export class AssessmentDomainLimitTracker {
    AssessmentDomainLimitTrackerId: Number;
    UserAssessmentId: Number;
    DomainId: Number;
    ComplexityId: Number;
    AssessmentDomainLimit: Number;
    QuestionsPresentedCount: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}