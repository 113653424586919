export class Client {
    ClientId: number;
    ClientName: string;
    ClientConfigurationJson: string;
    CreatedBy: string;
    CreatedDate: Date;
    ApplicationUri: string;
    LoweredApplicationUri: string;
    ModifiedDate: string;
    DeletedDate: string;
    ModifiedBy: string;
    DeletedBy: string;
}