export class UserAssessmentSummaryModel {
    UserAssessmentId: string;
    CandidateName: string;
    AssessmentName: string;
    AssessmentDate: Date;
    AssessmentDuration: string;
    UserAssessmentSkillReport: Array<UserAssessmentSkillReport>;
}

export class UserAssessmentSkillReport {
    SkillId: number;
    SkillName: string;
    ComplexityBasic: string;
    ScoreBasic: string;
    ComplexityIntermediate: string;
    ScoreIntermediate: string;
    ComplexityAdvanced: string;
    ScoreAdvanced: string;
}