import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../_services';
import { userDetails } from '@app/_models';
import { RoleEnum } from '../../_models/enum/role-enum';
declare var $: any;
declare const manageLoader: any;

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.less']
})
export class HomeHeaderComponent implements OnInit {
  user: userDetails;
  roleEnum;
  constructor(private accountService: AccountService) {
    this.accountService.getuserdata().subscribe((response: any) => {
      this.user = response.data;
    });
  }

  ngOnInit(): void {
    this.roleEnum = RoleEnum;
  }

  logout() {
    this.accountService.logout();
  }
}