export class SkillReportModel {
    PrimaryDomainId?: number;
    PrimaryDomainName?: string;
    DomainId?: number;
    DomianName?: string;
    ComplexityId?: number;
    BasicNoOfQuestions?: number;
    IntermediateNoOfQuestions?: number;
    ComplexNoOfQuestions?: number;
    BasicCodingQuestions?: number;
    IntermediateCodingQuestions?: number;
    ComplexCodingQuestions?: number;
}