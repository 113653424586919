export class PrimaryDomain {
    PrimaryDomainId: number;
    PrimaryDomainName: string;
    PrimaryDomainShortName: string;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}