<h1>Panel Review </h1>
<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<div id="horizontal-form">
    <table class="table table-hover">
        <thead class="bordered-darkorange">
            <tr>
                <th>Question</th>
                <th>User Answer</th>
                <th>Rating</th>
                <th>Is Correct Answer</th>
                <th>Remarks</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assessment of userAssessmentReviewModel; let i = index;">
                <td class="w-20">
                    <div [innerHtml]="assessment.Question"></div>
                </td>
                <td class="w-40">
                    <div [innerHtml]="assessment.UserAnswer"></div>
                </td>
                <td class="assessmentAction w-10">
                    <select #myRating  (change)="onRatingChange(myRating.value, i)" class="rating-control" [value]="assessment.Rating" >
                        <option value="0"></option>
                        <option *ngFor="let i of [1,2,3,4,5]">{{i}}</option>
                    </select>
                </td>
                <td class="assessmentAction w-10">
                    <input #myCorrectAnswer type="checkbox" id="vehicle1" name="vehicle1" class="check-box-control" [value]="assessment.IsAnswerCorrect" (change)="onCorrectAnswerSelected($event, i)">                      
                </td>
                <td class="assessmentAction">                    
                    <textarea #myRemarks id="txtArea" rows="2" cols="20" [value]="assessment.Remarks" (input)="onRemarksChange(myRemarks.value, i)"></textarea>
                </td>
            </tr>
        </tbody>
    </table>
    <br>
    <button class="btn-danger mr-5" (click)="cancelSkillData(); dialogRef.close(false)">
        Cancel</button>
    <button type="submit" class="btn-success mr-5" (click)="onSubmit()">Submit</button>
    <span *ngIf="isValidationError" class="validation-error"> {{validationData}} </span>
</div>