import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { UserAssessmentResponse, UserAssessmentReviewModel } from '@app/_models';
import { ToolbarService, LinkService, ImageService, HtmlEditorService } from '@syncfusion/ej2-angular-richtexteditor';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
declare const manageLoader: any;
import { ToastrService } from 'ngx-toastr';
import { PanelService  } from '@app/_services';

@Component({
  selector: 'app-panel-review',
  templateUrl: './panel-review.component.html',
  styleUrls: ['./panel-review.component.less'],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})

export class PanelReviewComponent implements OnInit {
  submitted = false;
  isValidationError = false;
  isFormVisible = false;
  validationData = "";

  isLoading = false;
  userAssessmentReviewModel: UserAssessmentResponse[];
  userAssessmentReviewData = new  UserAssessmentReviewModel();

  constructor(
    private _fb: FormBuilder,
    private panelService: PanelService,
    public dialogRef: MatDialogRef<PanelReviewComponent>,
    private toastrService: ToastrService,
    @Inject(MAT_DIALOG_DATA) data: { message: string, assessmentData: UserAssessmentResponse[], userAssessmentId: string, userId: number },
  ) {
    this.userAssessmentReviewModel = data?.assessmentData;
    this.userAssessmentReviewData.UserAssessmentId = data?.userAssessmentId;
    this.userAssessmentReviewData.UserId = data?.userId;    
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    this.toastrService.clear();
    this.isValidationError = false;
    this.validationData = '';
    this.userAssessmentReviewData.UserResponses=[];    
    if (this.validateRating()) {
      this.isValidationError = true;
      this.validationData = 'Please provide rating for all Questions';
      return;
    }
    if (this.validateRemarks()) {
      this.isValidationError = true;
      this.validationData = 'Fill add remarks for all Questions';
      return;
    }    
    this.userAssessmentReviewData.UserResponses=this.userAssessmentReviewModel;       
    this.isLoading = true;
    this.panelService.updatePanelReviewData(this.userAssessmentReviewData).pipe().subscribe((response:any)=>{
      if (response.Success == true) {
        this.isLoading = false;        
        this.submitAndCloseDialog();
      }
      else {
        this.isLoading = false;
        this.toastrService.error(response.errorMessage);
      }
    });
  }

  submitAndCloseDialog(): void {
    this.dialogRef.close({
      clicked: 'submit',
    });
  }

  validateRating(): boolean {
    return this.userAssessmentReviewModel.some((ele) => {
      return ele.Rating === 0
    });
  }

  validateRemarks(): boolean {
    return this.userAssessmentReviewModel.some((ele) => {
      return (ele.Remarks === "" || ele.Remarks === null || ele.Remarks === undefined)
    });
  }


  cancelSkillData() {
    this.toastrService.clear();
    this.submitted = false;
    return false;    
  }
  onRatingChange(e: any, i: number): void {
    this.userAssessmentReviewModel[i].Rating = e;
  }
  onCorrectAnswerSelected(e: any, i: number): void {
    const isChecked = e.currentTarget?.checked;
    this.userAssessmentReviewModel[i].IsAnswerCorrect = isChecked;

  }
  onRemarksChange(e: any, i: number) {
    this.userAssessmentReviewModel[i].Remarks = e
  }

}