export class LibraryQuestion {
    LibraryQuestionId: number;
    AssessmentId: number;
    QuestionId: number;
    Order: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}