import { Component } from '@angular/core';
import { AccountService, } from './_services';
import { AuthGuard } from './_helpers';
import { Router } from '@angular/router';
import { User } from './_models';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    url: string;
}
@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    public Gd: any;
    url: string;
    loc: Location;

    constructor(private accountService: AccountService, private router: Router, private authGuard: AuthGuard, public dialog: MatDialog) {
        this.accountService.user.subscribe(x => this.user = x);
        this.Gd = authGuard;
        this.Gd.nextUrl$.subscribe(resp => {
            this.url = '/' + resp;
        });
    }

    logout() {
        this.accountService.logout();
    }
}