import { Type, EventEmitter, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';

export interface NotificationRef {
  onDispose: Observable<any>;
  dispose: () => void;
  componentRef?: ComponentRef<any>;
}

export interface NotificationConfig {
  component: Type<Disposable<any>>;
  data?: Object;
  position?: any;
  duration?: number;
}

export interface Disposable<T> {
  dispose: EventEmitter<T>;
  duration?: number;
}

export enum NotificationPosition {
  TOP = 'top',
  TOP_RIGHT = 'top-right',
  TOP_LEFT = 'top-left',
  BOTTOM = 'bottom',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_LEFT = 'bottom-left',
}