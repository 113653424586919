import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  UserAssessment, Question, AssessmentDomainLimitTracker, UserAssessmentQuestion, UserAssessmentResult,
  EvidenceReport,
  EvidenceReportSave,
  UserAssessmentGridModel,
  UserAssessmentEvidanceModel,
  UserAssessmentSummaryModel,
} from '@app/_models';
import { ConfigurationService } from './deployment/configuration-service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  userAssessmentUrl: string;
  submitAnswerUrl: string;
  getAssessmentByIdUrl: string;
  getNextQuestionUrl: string;
  assessmentDomainLimitTrackerUrl: string;
  userAssessmentTrackerUrl: string;
  pauseAssessmentUrl: string;
  restartAssessmentUrl: string;
  incrementTimerUrl: string;
  abortAssessmentUrl: string;
  timeoutAssessmentUrl: string;
  completeAssessmentUrl: string;
  startAssessmentUrl: string
  userAnswerDetailsUrl: string;
  getAssessmentResultsUrl: string;
  evidenceReportUrl: string;
  getEvidenceReportResultUrl: string;
  getEvidenceReportDetailsUrl: string;
  saveEvidenceReportDetailsUrl: string;
  userAssessmentUploadUrl: string;
  summaryReportUrl: string;

  constructor(
    private http: HttpClient,
    private appConfig: ConfigurationService,
    private accountService: AccountService
  ) {
    this.userAssessmentUrl = this.appConfig.getConfig().endpoints.getUserAssessment;
    this.submitAnswerUrl = this.appConfig.getConfig().endpoints.submitAnswer;
    this.getAssessmentByIdUrl = this.appConfig.getConfig().endpoints.getAssessmentById;
    this.getNextQuestionUrl = this.appConfig.getConfig().endpoints.getNextQuestion;
    this.assessmentDomainLimitTrackerUrl = this.appConfig.getConfig().endpoints.assessmentDomainLimitTracker;
    this.userAssessmentTrackerUrl = this.appConfig.getConfig().endpoints.userAssessmentTracker;
    this.pauseAssessmentUrl = this.appConfig.getConfig().endpoints.pauseAssessment;
    this.restartAssessmentUrl = this.appConfig.getConfig().endpoints.restartAssessment;
    this.incrementTimerUrl = this.appConfig.getConfig().endpoints.incrementTimer;
    this.abortAssessmentUrl = this.appConfig.getConfig().endpoints.abortAssessment;
    this.timeoutAssessmentUrl = this.appConfig.getConfig().endpoints.timeoutAssessment;
    this.completeAssessmentUrl = this.appConfig.getConfig().endpoints.completeAssessment;
    this.startAssessmentUrl = this.appConfig.getConfig().endpoints.startAssessment;
    this.userAnswerDetailsUrl = this.appConfig.getConfig().endpoints.getUserAnswerDetails;
    this.getAssessmentResultsUrl = this.appConfig.getConfig().endpoints.getAssessmentResults;
    this.evidenceReportUrl = this.appConfig.getConfig().endpoints.evidence_report;
    this.getEvidenceReportResultUrl = this.appConfig.getConfig().endpoints.get_evidence_report;
    this.getEvidenceReportDetailsUrl = this.appConfig.getConfig().endpoints.getUserAssessmentEvidenceDetails;
    this.saveEvidenceReportDetailsUrl = this.appConfig.getConfig().endpoints.saveUserAssessmentEvidenceDetails;
    this.userAssessmentUploadUrl = this.appConfig.getConfig().endpoints.userAssessmentUpload;
    this.summaryReportUrl = this.appConfig.getConfig().endpoints.summaryRport;
  }

  userAssessmentUpload(data: FormData) {
    var userdata = this.accountService.userValue;
    return this.http.post<any>(`${this.userAssessmentUploadUrl}`, data, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUserAssessmentList() {
    return this.http.get<UserAssessment[]>(this.userAssessmentUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.accountService.userValue.access_token) });
  }

  getUserAssessmentGridList() {
    return this.http.get<UserAssessmentGridModel[]>(this.userAssessmentUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.accountService.userValue.access_token) });
  }

  SubmitAnswer(userAssessmentId: string, questionId: number, answer: string, isAnswerCompiled: boolean) {
    var userdata = this.accountService.userValue;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('userAssessmentId', userAssessmentId.toString());
    urlSearchParams.append('questionId', questionId.toString());
    urlSearchParams.append('answer', answer);
    urlSearchParams.append('isAnswerCompiled', isAnswerCompiled ? 'true' : 'false');
    answer = this.sanitizetest(answer);
    let body = urlSearchParams.toString();
    return this.http.post<UserAssessment>(`${this.submitAnswerUrl}`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }
  sanitizetest(str) {
    var entityMap = {
      "&": " ;amp ",
      "<": " ;lt ",
      ">": " ;gt ",
      '"': ' ;quot ',
      "'": ' ;#39 ',
      "/": ' ;#x2F ',
      '.': ' ;dot '
    };
    return String(str).replace(/[&<>."'\/]/g, function (s) {
      return entityMap[s];
    });
  }

  getUserAssessmentById(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessment>(`${this.getAssessmentByIdUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getNextQuestion(userAssessmentId: string, domainId: number, complexityId: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<Question>(`${this.getNextQuestionUrl}/${userAssessmentId}/${domainId}/${complexityId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUserAssessmentDomainLimitTracker(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<AssessmentDomainLimitTracker>(`${this.assessmentDomainLimitTrackerUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUserAssessmentTracker(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessmentQuestion>(`${this.userAssessmentTrackerUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  pauseAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.pauseAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  restartAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.restartAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  incrementTimer(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.incrementTimerUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  abortAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.abortAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  timeoutAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.timeoutAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  completeAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.completeAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  startAssessment(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.startAssessmentUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUserAnswerDetails(questionId: string, userResponseId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.userAnswerDetailsUrl}/${questionId}/${userResponseId}`);
  }

  getUserAssessmentResults(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessmentResult>(`${this.getAssessmentResultsUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getEvidenceReport(url: string) {
    return this.http.get<EvidenceReport>(url, { headers: new HttpHeaders().append('accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9') })
  }

  updateEvidenceReport(report: EvidenceReportSave) {
    var userdata = this.accountService.userValue;
    return this.http.post<void>(`${this.evidenceReportUrl}`, report, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  getEvidenceReportResults(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<any>(`${this.getEvidenceReportResultUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }

  getUserEvidenceDetails(userAssessmentId: string) {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessmentEvidanceModel[]>(`${this.getEvidenceReportDetailsUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }
  saveUserEvidenceDetails(report: UserAssessmentEvidanceModel) {
    var userdata = this.accountService.userValue;
    return this.http.post<void>(`${this.saveEvidenceReportDetailsUrl}`, report, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }

  getUserAssessmentSummaryReport(userAssessmentId: string) {
    return this.http.get<UserAssessmentSummaryModel>(`${this.summaryReportUrl}/${userAssessmentId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + this.accountService.userValue.access_token) });
  }
}
