import { Answer } from './answer';
import { Question } from './question';

export class UserAssessmentResult {
    UserResponseId: number;
    userAssessmentTracker: string;
    UserAnswer: string;
    isCorrectAnswer: boolean;
    MultiAnswer: boolean;
    Question: Question;
    Answers: Answer[];
}