import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Subject } from 'rxjs';
import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    public loader$ = new Subject<boolean>();
    public nextUrl$ = new Subject<string>();

    constructor(private router: Router, private accountService: AccountService) {
        this.loader$.next(false);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.accountService.userValue;
        this.loader$.next(false);
        this.nextUrl$.next("");
        if (this.router.url == "/candass") {
            this.loader$.next(true);
            this.nextUrl$.next(route.url.toString());
            return false;
        }
        if (user) {
            return true;
        }
        this.router.navigate(['/account/intial'], { queryParams: { returnUrl: state.url } });
    }
}