import { Injectable, ComponentFactoryResolver, Renderer2, RendererFactory2, Inject, Injector, ComponentRef, ApplicationRef, EmbeddedViewRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { NotificationRef, NotificationConfig, Disposable, NotificationPosition } from './notification.models';

@Injectable()
export class NotificationService {
  private _renderer: Renderer2;
  private _containerRefs: { [key in NotificationPosition]?: ComponentRef<any> } = {};

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private rendererFactory: RendererFactory2,
    private appRef: ApplicationRef,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  show(config: NotificationConfig): NotificationRef {
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(config.component).create(this.injector);
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    if (config.data) {
      for (let input of Object.keys(config.data)) {
        componentRef.instance[input] = config.data[input];
      }
    }
    if (config.duration) {
      componentRef.instance.duration = config.duration;
      timer(config.duration).subscribe(() => this.dispose(componentRef));
    }
    const onDispose = componentRef.instance.dispose.pipe(take(1));
    onDispose.subscribe(() => this.dispose(componentRef));
    this.appendInContainer(domElem, config.position);
    return {
      onDispose,
      dispose: () => this.dispose(componentRef),
      componentRef
    }
  }

  private dispose(componentRef: ComponentRef<Disposable<any>>) {
    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  private createContainer(position: NotificationPosition = NotificationPosition.TOP_RIGHT) {
    const containerRef = this._renderer.createElement('div');
    this._renderer.addClass(containerRef, 'notifications-container');
    this._renderer.setStyle(containerRef, 'position', 'fixed');
    this._renderer.setStyle(containerRef, 'z-index', '999999');
    if (position === NotificationPosition.TOP || position === NotificationPosition.TOP_RIGHT || position === NotificationPosition.TOP_LEFT) {
      this._renderer.setStyle(containerRef, 'top', '0');
    }
    if (position === NotificationPosition.BOTTOM || position === NotificationPosition.BOTTOM_RIGHT || position === NotificationPosition.BOTTOM_LEFT) {
      this._renderer.setStyle(containerRef, 'bottom', '0');
    }
    if (position === NotificationPosition.TOP_LEFT || position === NotificationPosition.TOP || position === NotificationPosition.BOTTOM_LEFT || position === NotificationPosition.BOTTOM) {
      this._renderer.setStyle(containerRef, 'left', '0');
    }
    if (position === NotificationPosition.TOP_RIGHT || position === NotificationPosition.BOTTOM_RIGHT) {
      this._renderer.setStyle(containerRef, 'right', '0');
    }
    if (position === NotificationPosition.TOP || position === NotificationPosition.BOTTOM) {
      this._renderer.setStyle(containerRef, 'width', '100%');
    }
    this._renderer.appendChild(this.document.body, containerRef);
    this._containerRefs[position] = containerRef;
  }

  private appendInContainer(
    elem: HTMLElement,
    position: NotificationPosition = NotificationPosition.TOP_RIGHT) {
    if (!this._containerRefs[position]) this.createContainer(position);
    this._renderer.appendChild(this._containerRefs[position], elem);
  }
}