export class AssessmentQuestion {
    AssessmentQuestionId: number;
    AssessmentId: number;
    AssessmentName: string;
    QuestionId: number;
    QuestionText: string;
    AssessmentOrder: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}

export class AssessmentQuestionsViewModel {
    DomainId: number;
    DomainName: string;
    QuestionDetails: Array<QuestionDetailsModel>;
}

export class QuestionDetailsModel {
    ComplexityId: number;
    ComplexityLevel: string;
    QuestionTypeId: number;
    QuestionTypeName: string;
    NoOfQuestionsAdded: number;
    TotalNoOfQuestions: number;
}