import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
import { AuthGuard } from './_helpers';
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const usersModule = () => import('./users/users.module').then(x => x.UsersModule);
const assessmentModule = () => import('./_modules/assessments/assessments.module').then(x => x.AssessmentsModule);
const questionModule = () => import('./_modules/questions/questions.module').then(x => x.QuestionsModule);
const userAssessmentModule = () => import('./_modules/user-assessment/user-assessment.module').then(x => x.UserAssessmentModule);


const routes: Routes = [
    { path: '', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },
    { path: 'assess', loadChildren: assessmentModule },
    { path: 'ques', loadChildren: questionModule },
    { path: 'userass', loadChildren: userAssessmentModule },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }