export class Answer {
    AnswerId: number;
    AnswerText: string;
    QuestionId: number;
    AnswerOrder: number;
    CorrectAnswer: boolean;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}