export enum RoleEnum {
    Candidate = "1",
    Admin = "3",
    Company = "2",
    Recruiter = "4",
    Approver = "5",
    ProjectManager = "6",
    SeniorManagement = "7",
    QuestionOwner = "10",
    PortalAdmin = "11",
    CodeReviewPanel = "12"
}