import { AssessmentDomain } from './assessment-domain';
import { PrimaryDomain } from './primary-domain';
export class AssessmentDetailsNew {
    AssessmentDetailId: number;
    AssessmentId: number;
    DomainId: number;
    ComplexityId: number;
    BasicNoOfQuestions: number;
    IntermediateNoOfQuestions: number;
    ComplexNoOfQuestions: number;
    BasicCodingQuestions?: number;
    IntermediateCodingQuestions?: number;
    ComplexCodingQuestions?: number;
    TimeLimitInMinutes: number;
    CreatedBy?: string;
    CreatedDate?: Date;
    DeletedBy?: string;
    DeletedDate?: Date;
    ModifiedBy?: string;
    ModifiedDate?: Date;
    PrimaryDomainId?: number;
    isNewData?: boolean = false;
    PrimaryDomains?: Array<PrimaryDomain>;
    AssessmentDomains?: Array<AssessmentDomain>;
}