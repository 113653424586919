export class QuestionType {
    TypeId: number;
    TypeDescription: string;
    MultipleChoice: boolean;
    Coding: boolean;
    AllowMultipleSelection: boolean;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}