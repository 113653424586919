<div *ngIf="user">
    <app-home-header></app-home-header>
</div>
<!-- main app container -->
<section [ngClass]="{ 'form-section container': user }" class="banner-area-two banner-bg-two banner-bg-margin">
    <alert></alert>
    <router-outlet></router-outlet>
</section>
<div *ngIf="user">
    <app-home-footer></app-home-footer>
</div>