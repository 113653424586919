import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigurationService } from './_services/deployment/configuration-service';
import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';
import { HomeHeaderComponent } from './_components/home-header/home-header.component';
import { HomeFooterComponent } from './_components/home-footer/home-footer.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { AuthGuard } from './_helpers/auth.guard';
import { MatTabsModule } from '@angular/material/tabs';
import { NotificationModule } from './_components/notification/notification.module'
import { MaterialNotificationComponent } from './_components/material-notification.component';
import { ExcelService } from '../app/_services/excel.service';
import { LoaderModule } from '../app/_modules/loader/loader.module';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { PanelReviewComponent } from './home/panel-review/panel-review.component';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatAutocompleteModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        MatDialogModule,
        MatTabsModule,
        MatRadioModule,
        MatListModule,
        NotificationModule,
        LoaderModule,
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 5000, // 5 seconds
            progressBar: true,
            positionClass: 'toast-top-right',
            maxOpened: 3, //max limit to display,
            preventDuplicates: true
        }),
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        HomeHeaderComponent,
        HomeFooterComponent,
        MaterialNotificationComponent,PanelReviewComponent,],
    exports: [MatStepperModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatSliderModule,
        MatDialogModule,
        MatTabsModule,
        MatRadioModule,
        MatListModule, MatDatepickerModule, MatFormFieldModule, MatNativeDateModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        ConfigurationService,
        AuthGuard,
        ExcelService,
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };