import { PrimaryDomain } from './primary-domain';
export class AssessmentDomain {
    DomainId: number;
    DomainName: string;
    DomainShortName: string;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    CompilerId?: number;
    PrimaryDomainId?: number;
    PrimaryDomain?: PrimaryDomain;
}