export class UserAssessmentEvidanceModel {
    UserAssessmentEvidenceId: number;
    UserAssessmentId: string
    TestAttemptId: string;
    HashedTestAttemptId: string;
}

export class BasicProctorModel {
    clientID: string;
    clientSecret: string;
    testAttemptId: string;
    hashedTestAttemptId: string;
}

export class ProctorResults {
    identifier: string;
    link: string;
    status: string;
    duration: string;
    startedAt: string;
    stoppedAt: string;
    score: string;
    student: string;
    signedAt: string;
    conclusion: string;
    averages: ProctorResultAverages;
    verified: string;
}

export class ProctorResultAverages {
    b1: string;
    b2: string;
    b3: string;
    c1: string;
    c2: string;
    c3: string;
    c4: string;
    c5: string;
    k1: string;
    m1: string;
    m2: string;
    n1: string;
    n2: string;
    s1: string;
    s2: string;
}