import { Component, OnInit } from '@angular/core';
import { DashboardGridModel, userDetails } from '@app/_models';
import { CandidateService, PanelService, AccountService, CompanyService  } from '@app/_services';
import { Assessment, UserAssessment, RoleEnum, UserAssessmentStatus, UserAssessmentGridModel, UserAssessmentPanelGridModel, UserAssessmentResult, UserAssessmentReviewModel, UserAssessmentResponse } from '@app/_models';
declare var $: any;
declare const manageLoader: any;
import { Router } from '@angular/router';
import { NotificationService } from '../_components/notification/notification.service';
import { NotificationPosition } from '../_components/notification/notification.models';
import { Observable, interval } from 'rxjs';
import { MaterialNotificationComponent } from '../_components/material-notification.component';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PanelReviewComponent } from './panel-review/panel-review.component';
import { ToastrService } from 'ngx-toastr';

@Component({ templateUrl: 'home.component.html' },
)
export class HomeComponent implements OnInit {
  roleEnum;
  isCandidate: boolean = false;
  isAdmin: boolean = false;
  isPanel = false;
  assessmentData: Assessment[] = [];
  userAssessmentData: UserAssessmentGridModel[] = [];
  dashboardData: DashboardGridModel;
  userAssessmentStatus;
  user: userDetails;
  isLoading = false;
  minDate = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
  maxDate = new FormControl(new Date());
  userAssessmentPanelGridData: UserAssessmentPanelGridModel[] = [];
  userAssessmentReviewModel: UserAssessmentReviewModel[] = [];
  userAssessmentResponse: UserAssessmentResponse[] = [];
  userId: number;


  constructor(private accountService: AccountService,
    private candidateService: CandidateService,
    private notification: NotificationService,
    private router: Router,
    private companyService: CompanyService,
    private panelService: PanelService,
    private dialog: MatDialog,
    private toastrService: ToastrService,) {
    this.roleEnum = RoleEnum;
    this.userAssessmentStatus = UserAssessmentStatus;
    this.accountService.getuserdata().subscribe((response: any) => {
      var user = response.data;
      if (user.RoleId == this.roleEnum.Candidate) {
        this.isCandidate = true;
        this.getUserAssessmentList();
      } else if (user.RoleId == this.roleEnum.PortalAdmin) {
        this.isAdmin = true;
        this.getDashboardDetails();
      }
      else if (user.RoleId == this.roleEnum.CodeReviewPanel) {
        this.userId = user?.UserId
        this.isPanel = true;
        this.getUserAssessmentForPanel(user?.UserId);
      }
    });
  }

  startUserAssessment(assessment: UserAssessment) {
    if (assessment.UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      this.candidateService.restartAssessment(assessment.UserAssessmentId).subscribe((response: any) => {
        this.router.navigate(['/assess/assterms', assessment.UserAssessmentId]);
      });
    }
    else {
      this.router.navigate(['/assess/assterms', assessment.UserAssessmentId]);
    }
  }

  getColorForStatus(UserAssessmentStatusId: number): string {
    let returnColor = '';
    if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Start) {
      returnColor = 'btn-palegreen';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      returnColor = 'btn-yellow';
    }
    else {
      returnColor = 'btn-darkorange';
    }
    return returnColor;
  }

  replaceStatusByName(UserAssessmentStatusId: number): string {
    let returnStstus = '';
    if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Start) {
      returnStstus = 'Start';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      returnStstus = 'Pause';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Restart) {
      returnStstus = 'Restart';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Complete) {
      returnStstus = 'Complete';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Abort) {
      returnStstus = 'Abort';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.TimeOut) {
      returnStstus = 'TimeOut';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Started) {
      returnStstus = 'Started';
    }
    else {
      returnStstus = 'NA';
    }
    return returnStstus;
  }

  getUserAssessmentList() {
    this.userAssessmentData = [];
    manageLoader('load');
    this.isLoading = true;
    this.candidateService.getUserAssessmentGridList().subscribe((response: any) => {
      this.userAssessmentData.push(...response.data);
      manageLoader();
      this.isLoading = false;
    });
  }

  getTotalScoreNumber(assessment: UserAssessmentGridModel): string {
    let returnVal = '';
    let score = assessment.UserAssessmentStatusId.toString() === this.userAssessmentStatus.Complete ? assessment.TotalScore : assessment.RunningScore;
    if (score) {
      returnVal = '/' + assessment.TotalQuestions;
    }
    return returnVal;
  }

  ngOnInit() {
  }

  onClick(time) {
    this.showNotification('Top right Notification!', time, NotificationPosition.BOTTOM_RIGHT);
  }

  showNotification(
    title: string,
    time: number,
    position: NotificationPosition
  ): Observable<any> {
    return this.notification.show({
      component: MaterialNotificationComponent,
      position: position,
      duration: +time * 1000,
      data: {
        title
      }
    }).onDispose;
  }

  getDashboardDetails() {
    manageLoader('load');
    this.isLoading = true;
    let startDate: Date = new Date(this.minDate.value);
    let endDate: Date = new Date(this.maxDate.value);
    this.companyService.getDashboardDetails(this.getFormattedDate(startDate), this.getFormattedDate(endDate)).subscribe((response: any) => {
      this.dashboardData = response.data;
      console.log(this.dashboardData);
      manageLoader();
      this.isLoading = false;
    });
  }


  getFormattedDate(date: Date) {
    return `${new Date(date).getMonth() + 1}-${new Date(date).getDate()}-${new Date(date).getFullYear()}`;
  }

  getUserAssessmentForPanel(panelId: number): void {
    this.userAssessmentPanelGridData = [];
    manageLoader('load');
    this.isLoading = true;
    this.panelService.getUserAssessmentsForPanel(panelId).subscribe((response: any) => {      
      this.userAssessmentPanelGridData.push(...response.data);
      manageLoader();
      this.isLoading = false;
    });
  }

  getUserAssessmentResultsData(userAssessmentId: string): void {
    //userAssessmentId = '0D7459C6-2156-411F-B9D7-50D7857B11E1';
    this.userAssessmentReviewModel = [];
    this.userAssessmentResponse = [];
    manageLoader('load');
    this.isLoading = true;
    this.candidateService.getUserAssessmentResults(userAssessmentId).pipe().subscribe((response: any) => {
      console.log(response.data);
      if (response.data) {
        response?.data?.forEach((data: UserAssessmentResult) => {
          if (data?.Question?.QuestionTypeId === 6) {
            var userAssessmentReviewModelData = new UserAssessmentReviewModel();
            userAssessmentReviewModelData.UserAssessmentId = userAssessmentId;
            userAssessmentReviewModelData.UserResponses = [];
            var userAssessmentResponse = new UserAssessmentResponse();
            userAssessmentResponse.UserResponseId = data?.UserResponseId;
            userAssessmentResponse.Question = data?.Question?.QuestionText;
            userAssessmentResponse.UserAnswer = data?.UserAnswer;
            userAssessmentResponse.Rating = 0;
            userAssessmentResponse.Remarks = '';
            userAssessmentResponse.IsAnswerCorrect = false;
            userAssessmentReviewModelData?.UserResponses?.push(userAssessmentResponse);
            this.userAssessmentResponse.push(userAssessmentResponse);
            this.userAssessmentReviewModel.push(userAssessmentReviewModelData);
          }
        });
        manageLoader();
        this.isLoading = false;
        if(this.userAssessmentResponse.length)
        {this.openPanelReviewDialog(userAssessmentId);}
        else{this.toastrService.warning('No questions to review')}
      }
    });
  }

  openPanelReviewDialog(userAssessmentId: string): void {
    this.toastrService.clear();
    const dialogRef = this.dialog.open(PanelReviewComponent, {
      width: '1230px',
      height: '600px',
      data: {
        assessmentData: this.userAssessmentResponse,
        userAssessmentId: userAssessmentId,
        userId: this.userId,

      },
    });
    dialogRef.afterClosed().subscribe((data) => {      
      if (data?.clicked === 'submit') {
        this.toastrService.success('Assessment reviewed successfully');
      }
    });
  }
}