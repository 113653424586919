import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, userDetails, UserAssessmentPanelGridModel, UserAssessmentReviewModel, UserAssessmentResponse } from '@app/_models';
import { ConfigurationService } from './deployment/configuration-service';
import { AccountService } from './account.service';

@Injectable({
  providedIn: 'root'
})
export class PanelService {
  private userSubject: BehaviorSubject<User>;
  private userDeatilsSubject: BehaviorSubject<userDetails>;
  userAssessmentPanel: string;

  constructor(
    private http: HttpClient,
    private appConfig: ConfigurationService,
    private accountService: AccountService
  ) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.userDeatilsSubject = new BehaviorSubject<userDetails>(JSON.parse(localStorage.getItem('userdetails')));
    this.userAssessmentPanel = this.appConfig.getConfig().endpoints.userAssessmentPanel;
  }

  getAllPanelList() {
    var userdata = this.accountService.userValue;
    return this.http.get<userDetails[]>(`${this.userAssessmentPanel}/getAllPanels`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }
  addPanelUser(UserName: string, Name: string) {
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('UserName', UserName);
    urlSearchParams.append('Name', Name);
    let body = urlSearchParams.toString();
    return this.http.post<userDetails>(`${this.userAssessmentPanel}/savePanel`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/x-www-form-urlencoded') });
  }
  getUserAssessmentsForPanel(panelId: number) {
    var userdata = this.accountService.userValue;
    return this.http.get<UserAssessmentPanelGridModel[]>(`${this.userAssessmentPanel}/getUserAssessmetsForReview/${panelId}`, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token) });
  }
  updatePanelReviewData(userAssessmentReviewModel: UserAssessmentReviewModel) {    
    var userdata = this.accountService.userValue;
    var userdetails = this.userDeatilsSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('userdetails'))).value.data;        
    let body = {
      UserAssessmentId: userAssessmentReviewModel.UserAssessmentId,
      UserId: userAssessmentReviewModel.UserId,
      UserResponses:userAssessmentReviewModel?.UserResponses
    };    
    return this.http.post<UserAssessmentPanelGridModel[]>(`${this.userAssessmentPanel}/reviewUserAssessment`, body, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + userdata.access_token).append('Content-Type', 'application/json') });
  }
}