export class UserAssessmentGridModel {
    UserAssessmentId: string;
    AssessmentId: Number;
    AssessmentName: string;
    UserAssessmentStatusId: number;
    TimeRemainingInMinutes: number;
    RunningScore: number;
    TotalScore?: number;
    Attempts?: string;
    ClientId?: number;
    ClientNmae?: string;
    CreatedDate?: Date;
    Duration?: number;
    EndDateTime?: Date;
    ShortUrl?: string;
    StartDateTime?: Date;
    StatusUpdateDateTime?: Date;
    UserId?: number;
    TotalQuestions?: string;
}