
import { Injectable } from '@angular/core';
import { AppConfig } from './app-config';
import { localAppConfig } from './local';
import { prodAppConfig } from './prod';
import { qaAppConfig } from './qa';
@Injectable({ providedIn: 'root' })
export class ConfigurationService {
    private appConfig: AppConfig;

    async loadAppConfig(): Promise<any> {
        this.appConfig = this.getEnvironment();
    }

    getConfig(): AppConfig {
        this.appConfig = this.getEnvironment();
        return this.appConfig;
    }

    private getEnvironment(): AppConfig {
        const host = location.host;
        switch (true) {
            case host.includes('localhost'):
                return localAppConfig;
            case host.includes('qa.myskilltest.com'):
                return qaAppConfig;
            default:
                return prodAppConfig;
        }
    };
}