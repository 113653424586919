import { Answer } from './answer';
export class Question {
    QuestionId: number;
    QuestionText: string;
    DomainId: number;
    DomainName: string;
    ComplexityId: number;
    ComplexityLevel: string;
    QuestionTypeId: number;
    TypeDescription: string;
    QuestionTextFormatted: string;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    answers: Array<Answer>;
    PrimaryDomainId: number;
    PrimaryDomainName: string;
}

export class AssessmentQuestionNew {
    AssessmentId: number;
    QuestionModel: Question;
}