import { AssessmentDomain } from './assessment-domain';
import { PrimaryDomain } from './primary-domain';

export class AssessmentDetails {
    AssessmentDetailId: number;
    AssessmentId: number;
    PrimaryDomainId?: number;
    DomainId: number;
    ComplexityId: number;
    NoOfQuestions: number;
    NoOfMCQQuestions: number;
    NoOfCodingQuestions: number;
    TimeLimitInMinutes: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    PrimaryDomains?: Array<PrimaryDomain>;
    AssessmentDomains?: Array<AssessmentDomain>;
}