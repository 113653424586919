<h1 class="pageTitle">Dashboard</h1>
<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<div class="inner-content" *ngIf="isCandidate">
    <table class="table table-hover" *ngIf="isCandidate">
        <thead class="bordered-darkorange">
            <tr>
                <th>Assessment Name</th>
                <th>Status</th>
                <th>Date</th>
                <th>Company</th>
                <th>Duration</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assessment of userAssessmentData">
                <td>{{assessment.AssessmentName}}</td>
                <td>{{ replaceStatusByName(assessment.UserAssessmentStatusId)}}</td>
                <td>{{assessment.CreatedDate | date}}</td>
                <td>{{assessment.ClientNmae}}</td>
                <td>{{assessment.Duration}} {{ assessment.Duration && assessment.Duration > 1 ? 'mins' : ''}}</td>
                <td class="assessmentAction">
                    <a title="" (click)="startUserAssessment(assessment)" class="grid-anchor green"
                        *ngIf="assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Start">
                        <i class="far fa-check-circle"></i>Start Assessment
                    </a>
                    <a title="" class="shiny"
                        *ngIf="assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Pause || assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Restart || assessment.UserAssessmentStatusId.toString() === userAssessmentStatus.Started"
                        [disabled]="true">
                        <i class="far fa-check-circle"></i>Assessment Disabled
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="inner-content" *ngIf="isAdmin">
    <div class="card">
        <div class="cord-body">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field class="example-full-width">
                        <input matInput [formControl]="minDate" readonly="true" [matDatepicker]="fromPicker"
                            placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field class="example-full-width">
                        <input matInput [formControl]="maxDate" readonly="true" [matDatepicker]="toPicker"
                            placeholder="Choose a date">
                        <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                        <mat-datepicker #toPicker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <button type="submit" class="btn-success mr-5" (click)="getDashboardDetails()">Search</button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="dashboardData">
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">Number of Assessments Added</div>
                </div>
                <div class="cord-body">
                    {{dashboardData.NoOfAssessmentsCreated}}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">Number of Assessments Active</div>
                </div>
                <div class="cord-body">
                    {{dashboardData.NoOfAssessmentsActive}}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">Number of Assessments Completed</div>
                </div>
                <div class="cord-body">
                    {{dashboardData.NoOfAssessmentsCompleted}}
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">Number of Assessments Timed Out / Aborted</div>
                </div>
                <div class="cord-body">
                    {{dashboardData.NoOfAssessmentsAborted}}
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isPanel">
    <table class="table table-hover">
        <thead class="bordered-darkorange">
            <tr>
                <th>Assessment Name</th>
                <th>Candidate Name</th>
                <th>Is Reviewed</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let assessment of userAssessmentPanelGridData">
                <td>{{assessment.AssessmentName}}</td>
                <td>{{ assessment.CandidateName}}</td>
                <td>{{assessment.IsReviewed}}</td>
                <td class="assessmentAction" >
                    <a *ngIf="!assessment.IsReviewed" title="Review" class="grid-anchor blue" (click)="getUserAssessmentResultsData(assessment.UserAssessmentId)">
                        <i class="far fa-list-alt editIcon"></i>
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</div>