import { AssessmentDetails } from "./assessment-details";
import { AssessmentDetailsNew } from "./assessment-details-new";

export class Assessment {
    AllowPausing: string;
    AssessmentId: number;
    ClientId: number;
    AssessmentName: string;
    AttemptsAllowed: string;
    DomainId: number;
    TimeLimitInMinutes: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
    RandomizeQuestions: string;
    ReadQuestionsFromAssessment: boolean;
    IsProctorRequired: boolean;
    assessmentDetails: Array<AssessmentDetails>;
    assessmentDetailsNew: Array<AssessmentDetailsNew>;
    assessmentSkillDetails?: Array<AssessmentDetails>;
    IsFreezed?: boolean;
}