import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User, userDetails, Register, RegisterEmployer } from '@app/_models';
import { ConfigurationService } from './deployment/configuration-service';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;
    private userDetailsSubject: BehaviorSubject<userDetails>;
    public userdetaill: Observable<userDetails>;
    userUrl: string;
    registerUrl: string;
    userDetailsUrl: string;
    registerCompanyUrl: string;

    constructor(
        private router: Router,
        private http: HttpClient,
        private appConfig: ConfigurationService
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();
        this.userDetailsSubject = new BehaviorSubject<userDetails>(JSON.parse(localStorage.getItem('userdetails')));
        this.userdetaill = this.userDetailsSubject.asObservable();
        this.userUrl = this.appConfig.getConfig().endpoints.users;
        this.registerUrl = this.appConfig.getConfig().endpoints.register;
        this.userDetailsUrl = this.appConfig.getConfig().endpoints.userDetails;
        this.registerCompanyUrl = this.appConfig.getConfig().endpoints.registerCompany;
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('username', username);
        urlSearchParams.append('password', password);
        urlSearchParams.append('grant_type', 'password');
        this.userSubject.next(null);
        this.userDetailsSubject.next(null);
        let body = urlSearchParams.toString();
        return this.http.post<User>(this.userUrl, body, { headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded') }).pipe(map(user => {
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }));
    }

    logout() {
        localStorage.removeItem('user');
        this.userSubject.next(null);
        localStorage.removeItem('userdetails');
        this.userDetailsSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(user: Register, role: string): any {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('FirstName', user.firstName);
        urlSearchParams.append('LastName', user.lastName);
        urlSearchParams.append('UserName', user.username);
        urlSearchParams.append('Password', user.password);
        urlSearchParams.append('RoleId', role);
        let body = urlSearchParams.toString();
        return this.http.post<User>(this.registerUrl, body, { headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded') });
    }

    registerEmployer(user: RegisterEmployer): any {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('CompanyName', user.CompanyName);
        urlSearchParams.append('EmailAddress', user.EmailAddress);
        urlSearchParams.append('MobileNumber', user.MobileNumber);
        urlSearchParams.append('Country', user.Country);
        urlSearchParams.append('CreatedDate', user?.CreatedDate?.toLocaleDateString());
        let body = urlSearchParams.toString();
        return this.http.post<User>(this.registerCompanyUrl, body, { headers: new HttpHeaders().append('Content-Type', 'application/x-www-form-urlencoded') });
    }

    getUserDetails() {
        const user = this.userValue;
        return this.http.get<userDetails>(this.userDetailsUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + user.access_token) });
    }

    getuserdata(): Observable<any> {
        const user = this.userValue;
        localStorage.removeItem('userdetails');
        return this.http.get<userDetails>(this.userDetailsUrl, { headers: new HttpHeaders().append('Authorization', 'Bearer ' + user.access_token) }).pipe(map(userd => {
            localStorage.setItem('userdetails', JSON.stringify(userd));
            this.userDetailsSubject.next(userd);
            return userd;
        }));
    }

    public get userDetailsValue(): userDetails {
        return this.userDetailsSubject.value;
    }

    getAll() {
        return this.http.get<User[]>(this.userUrl);
    }

    getById(id: string) {
        return this.http.get<User>(`${this.userUrl}/${id}`);
    }

    update(id, params) {
        return this.http.put(`${this.userUrl}/${id}`, params).pipe(map(x => {
            return x;
        }));
    }

    delete(id: string) {
        return this.http.delete(`${this.userUrl}/${id}`).pipe(map(x => {
            return x;
        }));
    }
}