export class AssessmentLibrary {
    LibraryId: number;
    LibraryName: string;
    DomainId: number;
    CreatedBy: string;
    CreatedDate?: Date;
    DeletedBy: string;
    DeletedDate?: Date;
    ModifiedBy: string;
    ModifiedDate?: Date;
}